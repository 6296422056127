import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import rochia1Image from "../images/rochia1.jpg"
import rochia2Image from "../images/rochia2.jpg"

const RochiaDeMireasaPage = () => (
  <Layout>
    <SEO title="Rochia de mireasă" />
    <div className="drawer article-content">
      <h1 className="article-title">Rochia de mireasă</h1>

      <section>
        <p>
          Te-ai visat de mică în rochie de mireasă? Sau din contră, nu ți-ai
          imaginat niciodată ziua nunții și acum nu știi ce rochie să alegi?
        </p>
        <p>
          Chiar dacă te gândeai de mult la rochia de mireasă, cu siguranță de-a
          lungul anilor s-a mai schimbat imaginea despre rochia ideală. Probabil
          prima rochie imaginată era o rochie de prințesă, însă cu timpul ai
          trecut la rochii mai simple, mai comode, mai ușor de purtat. Sau poate
          copil fiind te gândeai la o nuntă atipică în pantaloni scurți și acum
          îți dorești o rochie uimitoare.
        </p>
      </section>

      <img src={rochia1Image} />

      <section>
        <p>
          Indiferent cum ar fi, cu siguranță îți vei schimba puțin imaginea
          rochiei de vis și după ce vei proba rochii. Sunt extrem de puține
          mirese atât de norocoase încât viseaza la o rochie, identifică una, o
          probează și aceea rămâne rochia pentru nuntă, restul trebuie să
          probeze câteva rochii până găsesc rochia potrivită.
        </p>
      </section>
      <section>
        <h3 className="paragh-title">Cum alegi totuși rochia potrivită? </h3>{" "}
        <br />
        <p>
          Uite-te online la câteva modele care ți-ar plăcea, aruncă o privire pe
          site-urile magazinelor și gândește-te și la ce tip de rochie te
          avantajeză pe tine. Stabilește un buget în așa fel încât să mergi
          direct în magazinele în care rochiile au un preț care se încadrează în
          bugetul propus de tine.
        </p>
      </section>
      <section>
        <h3 className="paragh-title">Mergi și probează.</h3>
        <br />
        <p>
          Cu siguranță timpul nu îți va permite șă mergi în multe magazine, însă
          dacă ai aruncat o privire pe modelele magazinelor în care plănuiești
          să mergi, cu siguranță vei fi mai eficientă în căutare. Poți merge și
          la târguri de nunți, unde vei găsi rochii de la mai multe magazine și
          la un preț mai mic, însă trebuie să iei totuși în calcul că este
          posibil ca rochia pe care ai vazut-o tu online să nu fie și la târg
          pentru că aici vin cu modelele din colecțiile trecute. Probează modele
          diferite, chiar diferite și de ceea ce îți propusesi inițial, astfel
          îți va fi mai ușor să identifici ce model ți se potrivește. Nu te
          panica dacă nici una din rochiile probate nu îți place. Multe din
          magazine au propiul atelier, așadar poți combina două modele diferite
          sau poți înlocui anumite elemente care nu îți plac din rochia
          respectivă.
        </p>
      </section>
      <section>
        <h3 className="paragh-title">Alege singură rochia.</h3>
        <br />
        <p>
          Este important să ții cont de sfatul dat de cei din magazin, ei își
          dau seama cel mai ușor ce model te avantajează și te pot sfătui și în
          legătura cu potrivirea elementelor propuse de tine. Însă vei vedea că
          cu cât arăți poze cu rochia mai multor persoane, cu cât părerile vor
          fi mai diverse și îți va fi mai greu să iei decizia finală. Este cea
          mai importantă zi pentru tine, așadar este important să te simți bine
          și să îți placă rochia aleasă. Nu uita să te gândești și la imaginea
          de ansamblu când alegi rochia. O rochie cu trenă va fi greu de purtat
          la o nuntă în grădină, o rochie cu multe aplicații nu este potrivită
          pentru nunta rustică, o rochie foarte delicată nu se potrivește cu
          decorurile extravagante. Nu te grăbi să cumperi rochia pe loc (decât
          dacă ești la târg și e o ofertă unică), fă poze și gândește-te puțin
          înainte să iei decizia finală.
        </p>
      </section>
      <section>
        <h3 className="paragh-title">
          Cu cât timp înainte de nuntă să cauți rochia?
        </h3>
        <br />
        <p>
          Dacă vrei o rochie customizată și creată de la 0 pentru tine termenele
          de execuție sunt între 3 și 6 luni. Dacă plănuiești să o iei direct
          dintr-un magazin sau de la târg, nu o lăsa totuși pe ultima sută de
          metri pentru că este posibil să nu o găsești chiar în primul magazin.
        </p>
      </section>

      <section>
        <p>
          Indiferent dacă alegi o rochie lungă sau scurtă, din mătase, tafta sau
          tulle, tip sirenă sau tip prințesă, cu dantelă sau simplă, nu uita că
          cel mai important este să îți placă ție și să te simți bine în rochia
          aleasă.
        </p>
      </section>

      <img src={rochia2Image} />

      <div className="nav-section">
        <Link to="/pastrareaBuchetului">
          ◀ Cum păstrăm buchetul de mireasă?{" "}
        </Link>
        <Link to="/cununiaReligioasa">▶ Cununia religioasa</Link>
      </div>
    </div>
  </Layout>
)

export default RochiaDeMireasaPage
